.Hinzufügen {
  width: 340px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.GR {
  width: 240px;
  min-height: 80px;
  outline: none;
  border: none;
  resize: none;
  background-color: #f4f6f8;
  border-radius: 12px 12px 12px 0;
  padding: 10px;
  text-align: left;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  overflow: scroll;
  scrollbar-width: none;
}
.EN {
  width: 220px;
  min-height: 50px;
  outline: none;
  border: none;
  resize: none;
  background-color: #f4f6f8;
  border-radius: 12px 12px 0 12px;
  padding: 10px;
  text-align: left;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-left: 100px;
  margin-top: 5px;
  overflow: scroll;
  scrollbar-width: none;
}
.FR {
  width: 170px;
  min-height: 40px;
  outline: none;
  border: none;
  resize: none;
  background-color: #f4f6f8;
  border-radius: 12px 12px 0 12px;
  padding: 10px;
  text-align: left;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-left: 150px;
  margin-top: 5px;
  overflow: scroll;
  scrollbar-width: none;
}
.Footer {
  width: 340px;
  min-height: 80px;
  font-size: 0.8rem;
  width: 80%;
  margin-top: 50px;
  margin-left: 10%;
  color: gray;
}
