.Spiel {
  width: 340px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.Up {
  width: 340px;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.Up::-webkit-scrollbar {
  display: none;
}
.Textarea {
  width: 320px;
  min-height: 60px;
  outline: none;
  border: none;
  resize: none;
  background-color: #f4f6f8;
  border-radius: 5px;
  padding: 10px;
  text-align: justify;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.Fußzeile {
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  line-height: 15px;
  color: rgba(150, 150, 150);
}
.Fußzeile label {
  color: #0ead97;
  text-shadow: 0 0 0.2rem rgb(14, 173, 151);
}
.ENS1 {
  width: 100%;
  min-height: 50px;
  padding-bottom: 10px;
  font-size: 0.85rem;
  color: rgb(71, 68, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ENS2 {
  width: 100%;
  min-height: 120px;
  padding-bottom: 10px;
  font-size: 0.85rem;
  color: rgb(71, 68, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ENS3 {
  width: 100%;
  min-height: 400px;
  padding-bottom: 10px;
  font-size: 0.85rem;
  color: rgb(71, 68, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ENS3 img {
  width: 60px;
  min-height: auto;
}
.EN {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  justify-content: flex-start;
}
.EN span {
  display: inline-block;
  max-width: 70%;
  background-color: rgb(243, 242, 247, 0.6);
  line-height: 18px;
  padding: 10px 20px;
  border-radius: 8px 8px 8px 0;
  word-wrap: break-word;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9rem;
  color: black;
}
.GR {
  width: 100%;
  min-height: auto;
  text-align: justify;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}
.GRBox {
  display: inline-block;
  max-width: 60%;
  line-height: 18px;
  padding: 10px 20px;
  border-radius: 8px 8px 0 8px;
  word-wrap: break-word;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9rem;
}
.Karte {
  font-size: 0.6rem;
  color: rgb(100, 100, 100);
  margin-right: 10px;
}
.Gif {
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Gif img {
  width: 35px;
  height: auto;
}
.Möchtest {
  display: flex;
  flex-direction: row;
}
.BTND2 {
  width: 120px;
  min-height: 30px;
  background-color: #dbf0f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #0ead97;
  cursor: pointer;
  font-size: 0.8rem;
  border: 1px solid #0ead97;
  margin-top: 8px;
  margin-right: 5px;
}
.BTND3 {
  width: 120px;
  min-height: 30px;
  background-color: #f3f2f7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(71, 68, 68);
  cursor: pointer;
  font-size: 0.8rem;
  border: 1px solid #f3f2f7;
  margin-top: 8px;
  margin-left: 5px;
}
