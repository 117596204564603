.RoadMap {
  width: 320px;
  min-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 15px;
}
.RM {
  width: 70px;
  min-height: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.Circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70px;
  min-height: 18px;
  transition: color 0.5s ease-in-out;
  cursor: pointer;
}
.C1 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease-in-out;
}
.C2 {
  width: 9px;
  min-height: 9px;
  border-radius: 50%;
  transition: background-color 0.5s ease-in-out;
}
.C3 {
  width: 25px;
  height: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s ease-in-out;
}
