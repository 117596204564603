.Loading {
  width: 360px;
  height: 100vh;
  margin-left: calc(50% - 180px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2e2e2d;
}
.Loading img {
  width: 200px;
  min-height: auto;
}
