.Up {
  width: 340px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.th0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 1.1rem;
  border: none;
  width: 340px;
  min-height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.th0 span {
  position: relative;
  left: 10px;
}
.th0 img {
  width: 120px;
  height: auto;
}
.th1 {
  display: flex;
  flex-wrap: wrap;
  width: 340px;
  gap: 3px;
  max-height: auto;
}
.th2 {
  flex: 0 0 auto;
  min-width: auto;
  height: 35px;
  color: rgb(0, 0, 0);
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 35px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  border: 1px solid gray;
}
.Typ {
  width: 100px;
  min-height: 35px;
  outline: none;
  border: none;
  resize: none;
  background-color: rgb(244, 246, 248, 0.5);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  font-family: Beiruti;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 1.2rem;
  white-space: 1px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.Wort {
  width: 100px;
  min-height: 35px;
  outline: none;
  border: none;
  resize: none;
  background-color: rgb(244, 246, 248, 0.5);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  font-family: Beiruti;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 1.2rem;
  white-space: 1px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
}
.Forms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.EmptyText {
  width: 150px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(150, 150, 150);
  font-size: 0.9rem;
  margin-left: 95px;
}
.EmptyText img {
  width: 100px;
  min-height: auto;
}
.Tops {
  width: 340px;
  height: 100px;
  display: flex;
  flex-direction: column;
}
.Buttons {
  width: 340px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.Buttons::-webkit-scrollbar {
  display: none;
}
