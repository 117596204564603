.Main {
  width: 340px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Account {
  display: flex;
  flex-direction: row;
  width: 270px;
  min-height: auto;
  margin-top: -250px;
}
.AUS {
  width: 50px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}
.COFFEE {
  width: 170px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ANS {
  width: 50px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
img {
  width: 180px;
  height: auto;
}
.Info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.AN {
  font-weight: bold;
}
.GUTEN {
  margin-top: 20px;
}
.GUTEN > :nth-child(1) {
  font-weight: bold;
  margin-right: 8px;
}
.GUTEN > :nth-child(3) {
  position: relative;
  font-size: 0.8rem;
  left: -15px;
  top: -15px;
}
.Ring {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: white;
  position: relative;
  margin-top: -150px;
}
.Ring::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: conic-gradient(
    rgba(74, 34, 30) 0% var(--stop1),
    rgba(244, 246, 248) var(--stop1) 100%
  );
  z-index: -1;
}
svg {
  margin-top: -50px;
}
.Ring img {
  width: 100px;
  min-height: 100px;
  margin-top: 3px;
  margin-left: -3px;
}
.D {
  position: absolute;
  width: 30px;
  height: auto;
  z-index: 1000;
  margin-left: 132px;
}
