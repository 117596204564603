.Up {
  width: 340px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
.Search {
  width: 340px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Input {
  width: 240px;
  min-height: 33px;
  outline: none;
  padding-right: 30px;
  padding-left: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}
.InputContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.SearchIcon {
  position: absolute;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}
.SearchIcons {
  margin-top: 5px;
}
.Items {
  display: flex;
  flex-wrap: wrap;
  width: 340px;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
  gap: 10px;
  max-height: calc(100vh - 180px);
}
.Items::-webkit-scrollbar {
  display: none;
}
.item {
  flex: 0 0 auto;
  min-width: auto;
  height: 35px;
  background-color: rgb(150, 150, 150, 0.2);
  color: rgb(0, 0, 0);
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 35px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}
.Itemdetailes {
  width: 340px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.Itemdetailes::-webkit-scrollbar {
  display: none;
}
.Back {
  margin-left: 8px;
  margin-top: 8px;
  cursor: pointer;
}
.th0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 1.1rem;
  border: none;
  width: 340px;
  min-height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.th0 span {
  position: relative;
  left: 10px;
}
.th0 img {
  width: 120px;
  height: auto;
}
.th1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  width: 340px;
  min-height: 25px;
  margin-top: 4px;
}
.th2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  width: 100px;
  min-height: 25px;
  border: 1px solid gray;
  border-radius: 3px;
}
.th3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  width: 238px;
  min-height: 25px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: 2px;
}
.Itemsdetailes {
  width: 100%;
  height: calc(100vh - 130px);
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.Header {
  width: 340px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgb(238, 236, 236);
  margin-bottom: 10px;
}
.H1 {
  width: 300px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.H2 {
  width: 40px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.angoor {
  width: 46px;
  height: 46px;
}
.H1 span {
  font-size: 0.8rem;
  color: rgb(98, 99, 99);
  line-height: 15px;
  margin-left: 8px;
  margin-top: 3px;
  width: 160px;
  text-align: left;
}
.Bodi {
  width: 100%;
  height: calc(100vh - 190px);
  white-space: pre-line;
  text-align: justify;
  direction: rtl;
  font-family: Beiruti;
  font-size: 1.1rem;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
}
.Bodi::-webkit-scrollbar {
  display: none;
}
.comma {
  width: 50px;
  height: auto;
  margin-top: 5px;
  margin-bottom: 10px;
}
