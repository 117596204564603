.Up {
  width: 340px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.GR {
  width: 320px;
  min-height: 45px;
  outline: none;
  border: none;
  resize: none;
  background-color: rgb(244, 246, 248, 0.5);
  border-radius: 12px 12px 12px 12px;
  padding: 10px;
  text-align: center;
  font-family: Beiruti;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 1rem;
  white-space: 1px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;
}
.EN {
  width: 320px;
  min-height: calc(100vh - 320px);
  outline: none;
  border: none;
  resize: none;
  background-color: rgb(244, 246, 248, 0.5);
  border-radius: 12px;
  padding: 10px;
  text-align: justify;
  font-family: Beiruti;
  line-height: 20px;
  margin-left: 0px;
  margin-top: 5px;
  direction: rtl;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
