body {
  margin: 0;
  cursor: default;
  display: flex;
  flex-direction: row;
  position: fixed;
  font-family: Poppins;
  color: #2e2e2d;
}
.App {
  width: 340px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-left: calc(50% - 170px);
  align-items: center;
  justify-content: center;
  text-align: center;
}
