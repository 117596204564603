.Gesamt {
  width: 340px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.Up {
  width: 340px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
.Search {
  width: 340px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.UpBodi {
  width: 340px;
  height: calc(100vh - 180px);
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.UpBodi::-webkit-scrollbar {
  display: none;
}
.Input {
  width: 240px;
  min-height: 33px;
  outline: none;
  padding-right: 30px;
  padding-left: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}
.InputContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.SearchIcon {
  position: absolute;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}
.SearchIcons {
  margin-top: 5px;
}
