.Tree {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.trr2 {
  width: 80px;
  min-height: auto;
  margin-top: calc(80px - 40px);
}
.AkkusativTree {
  text-transform: uppercase;
  color: #578dbe;
  font-size: 0.8rem;
}
.Wrapper {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
}
.WP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.WB {
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(150, 150, 150);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1px;
}
.Footers {
  display: flex;
  flex-direction: column;
  width: 340px;
  min-height: auto;
  direction: ltr;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}
.Span {
  direction: rtl;
}
.Label {
  color: black;
  font-weight: bold;
}
.Bodi {
  width: 100%;
  height: auto;
  text-align: justify;
  direction: rtl;
  font-family: Beiruti;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
