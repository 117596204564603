.Unicode {
  display: flex;
  flex-direction: row;
}
.Uni {
  cursor: pointer;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: -5px;
  transition: 0.5s;
}

.Uni:hover {
  background-color: rgb(253, 248, 180);
}
.Image {
  width: 120px;
  min-height: auto;
  margin-top: 15px;
}
