.Empty {
  width: 360px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
}
.Empty img {
  width: 200px;
  height: auto;
  margin-top: -100px;
}
.EmptyText1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  color: #d70022;
  text-shadow: 0 0 0.1rem #d70022;
}
.EmptyText2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.6rem;
  color: #4b5864;
}
