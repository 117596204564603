.Bodi {
  width: 340px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.Header {
  width: 340px;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Main {
  width: 340px;
  min-height: calc(100vh - 80px);
}
.Item {
  display: flex;
  flex-direction: row;
  width: 100px;
  cursor: pointer;
}
.Item span {
  margin-left: 3px;
  margin-top: 2px;
}
.SubHeader {
  width: 340px;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 0.8rem;
  margin-top: -15px;
}
.Menu {
  cursor: pointer;
  text-transform: uppercase;
}
