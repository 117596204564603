.Up {
  width: 340px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: #d6d6d6 #ffffff;
  -ms-overflow-style: none;
}
.Up::-webkit-scrollbar {
  display: none;
}
.GR {
  width: 310px;
  min-height: 35px;
  outline: none;
  border: 1px solid rgb(219, 217, 217);
  resize: none;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 1rem;
  white-space: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.InputBox {
  display: flex;
  flex-direction: row;
  width: 340px;
  min-height: auto;
}
.English {
  font-size: 0.75rem;
  margin-left: 3px;
  margin-top: 2px;
  color: gray;
}
.InputVerb1 {
  width: 170px;
  height: 5px;
  outline: none;
  border: none;
  resize: none;
  padding: 10px;
  text-align: center;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
}
.InputVerb2 {
  width: 170px;
  height: 5px;
  outline: none;
  border: none;
  resize: none;
  padding: 10px;
  text-align: center;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
}
.InputVerb3 {
  width: 140px;
  height: 5px;
  outline: none;
  border: none;
  resize: none;
  padding: 10px;
  text-align: center;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
}
input::placeholder {
  color: rgba(150, 150, 150, 0.3);
}
.Verben {
  width: 200px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  margin-left: calc(50% - 100px);
  opacity: 0.5;
  margin-top: 15px;
}
.Choosen {
  width: 340px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ChoosenTitile {
  font-size: 0.8rem;
  width: 50%;
}
.ChoosenInput {
  width: 150px;
  outline: none;
  border: 1px solid rgb(219, 217, 217);
  resize: none;
  border-radius: 5px;
  text-align: center;
  font-size: 0.85rem;
  font-family: Poppins;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 1rem;
  white-space: 1px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;
}
.Möchtest {
  display: flex;
  flex-direction: row;
}
.BTND3 {
  width: 120px;
  min-height: 35px;
  background-color: #f3f2f7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(71, 68, 68);
  cursor: pointer;
  font-size: 0.8rem;
  border: 1px solid #f3f2f7;
  margin-top: 8px;
  margin-left: 5px;
}
